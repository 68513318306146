<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Дабавить бренд организации" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row>
                <b-col cols="12">
                    <label for="">Имя бренд организации <i style="color: red;">*</i></label>
                    <b-input v-model="createBrand.name" placeholder="Имя бренд организации"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label for="">Код бренд организации <i style="color: red;">*</i></label>
                    <b-input v-model="createBrand.code" placeholder="Код бренд организации"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label for="">Организации <i style="color: red;">*</i></label>
                    <v-select
                    v-model="createBrand.organization_id"
                    placeholder="Организации"
                    :reduce="options => options.id"
                    :options="organizations"
                    label="name"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="mt-1">
                    <b-form-checkbox v-model="createBrand.is_active" checked="true" name="check-button" switch inline>
                        {{this.createBrand.is_active ? 'Активный' : ' Не активный'}}
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="addBrend">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="clearData">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
    export default {
        components: {
            vSelect
        },
        props: ['organizations'],
        data() {
            return {
                submitButtonDisabled: false,
                createBrand: {
                    name: '',
                    code: '',
                    organization_id: '',
                    is_active: false
                }
            }
        },
        methods: {
            addBrend() {
                this.submitButtonDisabled = true
                this.createBrand.is_active = this.createBrand.is_active ? 1 : 0
                this.$http
                    .post('SystemSettings/brands/createBrand', this.createBrand)
                    .then((res) => {
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                        })
                        this.clearData()
                        this.$emit('refresh')
                    })
                    .catch((err) => {
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                            props: {
                                title: 'Некорректные данные!',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: err.response.data.errors,
                            },
                        })
                    })
                    .finally(() => {
                        this.submitButtonDisabled = false
                    })
            },
            clearData() {
                this.$bvModal.hide(this.$route.name + 'CreateModal');
                this.createBrand = {
                    name: '',
                    code: '',
                    organization_id: '',
                    is_active: false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>